import { text } from "stream/consumers";

const colors = {
  // basic
  primary: "#000000",
  baseHover: "#5c84f0",
  baseText: "#000000",
  baseColor: "#000000",
  baseColor2: "#371675",
  textBlack: "#000000",
  textWhite: "#ffffff",
  baseBackground: "#FFFAFA",
  infoBoxBackground: "#fafafa",
  baseBoxBackground: "#fafafa",
  borderColor: "#ccc7c7",

  titleDay: "#0a0720",
  closeButton: "#0e0d0d",

  gold: "#FFD700",
  silver: "#C0C0C0",
  bronze: "#CD7F32",

  // card
  cardBackground: "#ffffff",

  // table header
  tableHeader: "#111927",
  tableBorder: "#9fa6ec",
  tableRow: "#f5f5f5",
  tableRowHover: "#e8e8e8",

  // buttons
  buttonText: "#000000",
  buttonBase: "#D3D3D3",
  buttonBase2: "#4cd8a9",
  buttonSelected: "#B0C4DE",
  buttonHover: "#A2B5CD",
  buttonHoverLight: "#99e8f3",
  buttonBackground: "#D3D3D3",
  buttonHoverBackground: "#A9A9A9",
  buttonLogin: "#ffffff",
  buttonLoginHover: "#163d91",
  disabledButton: "#000000",

  // line
  line: "#524b4b",

  // borders
  baseBorder: "#adaaaa",

  // inputs
  inputBackground: "#ffffff",

  // scoring
  score0th: "#FFD700", //golden color
  score1st: "#00FF00",
  score2nd: "#1E90FF",
  score3rd: "#FF4500",
  score4th: "#c4bfaf",
  score5th: "#cdd8cb",

  score3rd2: "#f17764",

  // box
  boxText: "#ffffff",

  textHover: "#5c84f0",

  menuText: "#333",
  infoBox: "#aef552",

  // navbor
  navBackground: "#000000",

  // header
  headerBackground: "#444040",

  // loading
  loadingBackground: "#0f2c4d",
  background2: "#0f2c4d",

  // best player
  bestPlayerBackground: "#1abc9c",

  // banner / promotion
  bannerBackground: "#4678b9",
  bannerBackground2: "#16a085",
  promotionBackground: "#121a4d",
  banner: "#3dbba2",

  // quiz
  quizBackground: "#000000",

  // search bar

  // difficulty
  difficulties: ["#none", "#78c469", "#24e24d", "#69727a", "#c43333", "#660505"],

  // entry
  entryBackground: "#f5f5f5",
  entryHoverBackground: "#e8e8e8",

  // ranking
  rankingBackground: "#f5f5f5",

  // success
  success: "#4caf50",
  warning: "#ff9800",
  error: "#f44336",

  // grass color
  grassColor1: "#3b7e42",
  grassColor2: "#2b5e33",
};

export default colors;
